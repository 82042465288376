<template>
    <TransitionRoot appear :show="isOpen" as="template">
      <Dialog as="div" class="relative z-50 " @close="close">
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in duration-200"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <DialogOverlay class="fixed inset-0 bg-black bg-opacity-50" />
        </TransitionChild>

        <div class="fixed w-3/4 md:w-full inset-0 overflow-y-auto">
          <div class="flex items-center justify-center min-h-full p-4 text-center">
            <TransitionChild
              as="template"
              enter="ease-out duration-300"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="ease-in duration-200"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
            >
              <DialogPanel class="w-full max-w-md p-6 bg-white rounded-lg shadow-lg">
                <div class="flex justify-between items-center">
                  <h1 class="text-lg font-medium text-gray-900">Login</h1>
                  <button @click="close" class="text-primary hover:text-gray-500">
                    <span class="sr-only">Close</span>
                    <i class="fas fa-times"></i>
                  </button>
                </div>

                <form class="space-y-5 dark:text-white text-start pt-5" @submit.prevent="handleSubmit">
                            <div>
                                <label for="Email">Email</label>
                                <div class="relative text-white-dark">
                                    <input v-model="formData.email" id="Email" type="email" placeholder="Enter Email" class="form-input ps-10 placeholder:text-white-dark" />
                                    <span class="absolute start-4 top-1/2 -translate-y-1/2">
                                        <icon-mail :fill="true" />
                                    </span>
                                </div>
                                <span v-if="errors.email" class="text-red-500 text-xs">{{ errors.email }}</span>
                            </div>
                            <div>
                                <label for="Password">Password</label>
                                <div class="relative text-white-dark">
                                    <input v-model="formData.password" id="Password" type="password" placeholder="Enter Password" class="form-input ps-10 placeholder:text-white-dark" />
                                    <span class="absolute start-4 top-1/2 -translate-y-1/2">
                                        <icon-lock-dots :fill="true" />
                                    </span>
                                </div>
                                <span v-if="errors.password" class="text-red-500 text-xs">{{ errors.password }}</span>
                            </div>
                            <div class="mt-6 text-start text-sm">
                                <p>forgot password ?   <NuxtLink to="/auth/boxed-password-reset" class="text-primary">Reset here</NuxtLink></p>
                            </div>

                            <button type="submit" class="btn bg-primary w-1/2  text-white !mt-6 border-0 uppercase shadow-[0_10px_20px_-10px_rgba(67,97,238,0.44)]" :disabled="loading">
                                <span v-if="loading">Loading...</span>
                                <span v-else>Sign in</span>
                            </button>
                        </form>
                <div class="mt-6 text-start">
                  <p>Don’t have an account?   <NuxtLink class="text-primary">Sign up</NuxtLink></p>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
  </template>

  <script lang="ts" setup>
  import { ref } from 'vue';
  import { Dialog, DialogOverlay, DialogPanel, TransitionRoot, TransitionChild } from '@headlessui/vue';
  import { createCrmLeadPublic } from '~/utils/apiService';
  import Swal from 'sweetalert2';
  import {showMessage} from '~/utils/index'
  import { useRouter } from 'vue-router';
  import { useAuthStore } from '@/stores/authStore';
  import { signIn } from '~/utils/apiService';


    const router = useRouter();
    const authStore = useAuthStore();
    const { setLocale } = useI18n();
    const isOpen =  ref(false);
    const props = defineProps({
    isOpen: {
      type: Boolean,
      required: true
    }
  });
  watch(() => props.isOpen, (newVal) => {
        if (newVal) {
            isOpen.value =true

        }
    });

  const emit = defineEmits(['close']);

  const name = ref('');
  const phone = ref('');
  const email = ref('');

  const close = () => {
    emit('close');
    isOpen.value =false
  };

// Form data
const formData: any = ref({
    email: '',
    password: '',
});

const errors: any = ref({
    email: '',
    password: '',
});

// Loading state
const loading = ref(false);

// Validation function
const validateForm = () => {
    let valid = true;

    if (!formData.value.email) {
        errors.value.email = 'Email is required';
        valid = false;
    } else {
        errors.value.email = '';
    }

    if (!formData.value.password) {
        errors.value.password = 'Password is required';
        valid = false;
    } else {
        errors.value.password = '';
    }

    return valid;
};

// Handle form submission
const handleSubmit = async () => {
    if (!validateForm()) {
        return;
    }

    loading.value = true;

    const params = {
        username: formData.value.email,
        password: formData.value.password,
    };

    try {
        const response = await signIn(params);
        // console.log('User Signed In:', response);
        // Store login data in authStore
        authStore.setUser({
            token: response.data.token,
            userId: response.data.user_id,
            companyId: response.data.company_id,
            partnerId: response.data.partner_id,
            partnerName: response.data.partner_name,
            accessIds: response.data.access_ids.data, // Store access_ids here
            is_trial_mode: response.data.is_trial_mode, // Store access_ids here
        });

        // Optionally save token to localStorage/sessionStorage
        localStorage.setItem('authToken', response.data.token);
        showMessage('Wellcome on Board', 'success');
        isOpen.value = false
        router.push('/');
    } catch (error) {
        showMessage('Login Failed, please check Email or Password', 'danger');
        console.error('Error signing in:', error);
    } finally {
        loading.value = false;
    }
};
  </script>

  <style scoped>
  /* Custom styles here if needed */
  </style>
